<script setup>

</script>

<template>
  <div class="container">
    大家好！我们是UTM Chinese Academic and ProfessionalSociety，从我们的名字就可以看出我们是一个以学术与职业发展方中心的人学生社図!<br>
    我们致力于在UTM打造一个互相帮助、积极进取的优质平台。在我们的社团中，不仅有担任TA、RA的学术大佬，也有拥有国内外丰富工作经验的职业精英。<br>
    我们会通过各种活动和资源交换，帮助社团成员们在学术和职业道路上取得更大的成就。<br>
    同时，我们也会利用社交媒体为社团外的华人学生们提供学术与职业发展的相关资讯，并不定期展开一些活动分享经验。
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #1F3979;
  color: #FFFFFF;
  font-size: .9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>